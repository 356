import Api from '../api/api'
import { push } from 'connected-react-router'
import jwt_decode from 'jwt-decode'
import { add, clear } from "react-redux-permissions"

// export const LOGIN_ACTION = 'LOGIN_ACTION'
// export const LOGIN_ERROR_ACTION = 'LOGIN_ERROR_ACTION'
// export const LOGGED_IN_ACTION = 'LOGGED_IN_ACTION'
// export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN'
export const CLEAR_AUTH = 'CLEAR_AUTH'
// export const SET_TEAM = 'SET_TEAM'
export const UPDATE_GLOBAL_DATA = 'UPDATE_APP_DATA'



// export const loggedInAction = (payload = {}) => {
//     return {
//         type: LOGGED_IN_ACTION,
//         payload
//     }
// }
// export const loginErrorAction = (payload = {}) => {
//     return {
//         type: LOGIN_ERROR_ACTION,
//         payload
//     }
// }
// export const updateAuthToken = (payload = {}) => {
//     return {
//         type: UPDATE_AUTH_TOKEN,
//         payload
//     }
// }
export const clearAuth = (payload = {}) => {
    return {
        type: CLEAR_AUTH,
        payload
    }
}
// export const setTeam = (payload = {}) => {
//     return {
//         type: SET_TEAM,
//         payload
//     }
// }
export const updateAppData = (payload = {}) => {
    return {
        type: UPDATE_GLOBAL_DATA,
        payload
    }
}

export const loginAction = (payload = {}) => {
    return function (dispatch, getState) {
        let promise = null

        promise = Api.post(Api.getApiBaseUrl() + 'auth/login', {
            body: payload,
            secured: false
        })

        promise.then(json => {
            delete json.success
            const user = jwt_decode(json.access_token)
            user.refreshToken = json.refreshToken;
            const payload = {
                ...json,
                user
            }
            localStorage.setItem('auth', JSON.stringify(payload))
            dispatch(clear())
            dispatch(updateAppData(payload))
            dispatch(updatePermissionsAction(user))
            dispatch(push('/'))
        }).catch((e) => {
            dispatch(updateAppData({
                showAuthenticationError: true
            }))
        })

        return promise;
    }
}

export const logoutAction = (payload = {}) => {
    return function (dispatch, getState) {
        return new Promise((res, rej) => {
            localStorage.removeItem('auth')
            dispatch(clear())
            dispatch(clearAuth())
            dispatch(push('/login'))
            res()
        })
    }
}

export const updatePermissionsAction = user => {
    return function (dispatch, getState) {
        for (let i = 0; i < user.roles.length; i++) {
            const role = user.roles[i];
            for (let j = 0; j < role.permissions.length; j++) {
                const permission = role.permissions[j];
                dispatch(add(permission.name))
            }
        }
    }
}

export const loadAuthFromStore = () => {
    return function (dispatch, getState) {
        return new Promise((res, rej) => {
            try {
                const auth = localStorage.getItem('auth')

                if (auth) {
                    const auth_obj = JSON.parse(auth)
                    dispatch(updateAppData(auth_obj))
                    dispatch(updatePermissionsAction(auth_obj.user))
                }
                else {
                    dispatch(push('/admin'))
                }
                res()
            }
            catch (e) {
                rej(e)
            }
        })
    }
}

export const requestUsersAction = (payload = {}) => {
    return function (dispatch, getState) {
        return Api.get(Api.getApiBaseUrl() + 'api/user', {
            body: payload
        })
            .then(json => {
                console.info(json)
            })
    }
}
