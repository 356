import { Container, makeStyles } from "@material-ui/core";
import MaterialTable from 'material-table';
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import Api from "../../common/api/api";
import MaterialTableCustomEditField from "../../common/utils/MaterialTableCustomEditField";
import tableIcons from "../../common/utils/MaterialTableIcons";
// import NewIndicationForm from './IndicationsForm'
import { useTranslation } from "react-i18next";
import PostFormDialog from "./PostFormDialog";
import { PlayArrow } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    fab: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
    },
}));

const emptyPost = { platformConfigurations: [], images: [] }

export default function PostTableComponent(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([])
    const tableRef = useRef(null);
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [currentPost, setCurrentPost] = useState({ ...emptyPost })
    const columns = [
        { title: t('Name'), field: 'name' },
        { title: t('Next execution'), field: 'nextExecution' },
        // { title: t('Platform Configurations'), render: row => {
        //     return row.platformConfigurations.map(x => x.name).join(', ')
        // } },
        { title: t('Active'), field: 'active' },
    ]

    useEffect(e => {
        if (tableRef.current) {
            //pasaba q cuando dabas adicionar una fila y cambiabas en el menu a otro listado, se mantenia abierto la edicion con datos del anterior
            tableRef.current.setState({
                ...tableRef.current.state,
                showAddRow: false
            })
        }

        loadData()
    }, [])

    const loadData = async () => {
        const result = await Api.get(Api.getApiBaseUrl() + `auto-post`)
        setData(result)
    }

    const handleFormSave = () => {
        setIsFormOpen(false)
        loadData()
    }

    const handleFormClose = () => {
        setIsFormOpen(false)
    }

    const handleFormOpen = () => {
        setIsFormOpen(true)
    }

    // AQUI: poner boton de ejecucion por tarea

    return (
        <React.Fragment>
            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={columns}
                data={data}
                title={t('Posts')}
                components={{
                    EditField: props => <MaterialTableCustomEditField {...props} />,
                }}
                actions={[
                    {
                        icon: tableIcons.Add,
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            setCurrentPost({ ...emptyPost })
                            setIsFormOpen(true)
                        }
                    },
                    {
                        icon: tableIcons.Edit,
                        onClick: (event, rowData) => {
                            setCurrentPost(rowData)
                            setIsFormOpen(true)
                        }
                    },
                    {
                        icon: tableIcons.Delete,
                        onClick: async (event, rowData) => {
                            try {
                                await Api.delete(Api.getApiBaseUrl() + `auto-post/${rowData.id}`)
                                const dataDelete = [...data];
                                const index = rowData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                            }
                            catch (e) {
                                console.log('ERROR', e);
                            }
                        }
                    },
                    {
                        icon: PlayArrow,
                        onClick: async (event, rowData) => {
                            try {
                                await Api.post(Api.getApiBaseUrl() + `auto-post/execute/${rowData.id}`, {
                                    body: {}
                                })
                                
                            }
                            catch (e) {
                                console.log('ERROR', e);
                            }
                        }
                    }
                ]}
            />

            <PostFormDialog open={isFormOpen} handleClose={handleFormClose} handleSave={handleFormSave} data={currentPost} />
        </React.Fragment>
    )
}

function format_column_name(string) {
    return (string.charAt(0).toUpperCase() + string.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2');
}