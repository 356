import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Container, Grid, Paper } from "@material-ui/core";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Api from "../../common/api/api";

export default function ProfileForm(props) {
  const { t, i18n } = useTranslation();
  const [ values, setValues ] = useState({});
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);
  const formRef = useRef();

  const handleOnChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const handleSaveClick = () => {
    formRef.current.submit()
  }

  const handleSubmit = async (event) => {
    setIsSaveButtonEnabled(false);
    try {
      await Api.post(
        Api.getApiBaseUrl() + `users/profile`,
        {
          body: values,
        }
      );
    }
    catch (e) {
      console.log(e);
    }

    setIsSaveButtonEnabled(true);
  }

  return (
    <Container>
      <Card>
        <CardHeader title={t('Profile')} />
        <CardContent>
          <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
            <Grid role="row">
              <Grid xs={12}>
                <TextValidator type="password" name="password" label={t('Password')}
                  InputLabelProps={{ shrink: true }}
                  value={values.password} onChange={handleOnChange}
                  required validators={['required']} errorMessages={[t('this field is required')]}
                  fullWidth
                />
              </Grid>
            </Grid>
          </ValidatorForm>
        </CardContent>
        <CardActions>
          <Button disabled={!isSaveButtonEnabled} color="primary" onClick={handleSaveClick}>{t('Save')}</Button>
        </CardActions>
      </Card>

    </Container>
  )
}