import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../common/api/api";

export default function ProfilePage() {

    const { t, i18n } = useTranslation();
    const [value, setValue] = useState()

    useEffect(() => {
        Api.get(Api.getApiBaseUrl() + 'profile')
            .then(response => {
                setValue(JSON.stringify(response))
            })
    }, [])

    return (
        <Container maxWidth="md" component="main">
            <h1>{t('Profile')}</h1>
            <p>{value}</p>
        </Container>
    )
}