import { makeStyles } from "@material-ui/core";
import MaterialTable from 'material-table';
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import Api from "../../common/api/api";
import MaterialTableCustomEditField from "../../common/utils/MaterialTableCustomEditField";
import tableIcons from "../../common/utils/MaterialTableIcons";
// import NewIndicationForm from './IndicationsForm'
import { useTranslation } from "react-i18next";
import PostFormDialog from "./PostFormDialog";
import { AccountCircle, Done, PlayArrow } from "@material-ui/icons";
import LoginIcon from '@material-ui/icons/';
import platformLoginFactory from "./platformLoginFactory/platformLoginFactory";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    fab: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
    },
}));

const emptyPost = { platforms: [], images: [] }

export default function PlatformConfigurationTableComponent(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([])
    const tableRef = useRef(null);
    const [columns, setColumns] = useState([])
    const [platforms, setPlatforms] = useState([])
    const [loginComponent, setLoginComponent] = useState('')

    useEffect(e => {
        if (tableRef.current) {
            //pasaba q cuando dabas adicionar una fila y cambiabas en el menu a otro listado, se mantenia abierto la edicion con datos del anterior
            tableRef.current.setState({
                ...tableRef.current.state,
                showAddRow: false
            })
        }

        loadData();

        (async () => {
            const result = await Api.get(Api.getApiBaseUrl() + `auto-post/platform`)
            setPlatforms(result)
        })()
    }, [])

    useEffect(() => {
        setColumns([
            { title: t('Name'), field: 'name' },
            {
                title: t('Platform'),
                field: 'platform',
                type: 'select',
                render: (rowData) => {
                    if (!rowData['platform']) {
                        return (
                            <span></span>
                        )
                    }

                    const to_show = platforms.find((x) => x.id === rowData['platform'].id)
                    return (
                        <span>{to_show ? to_show.name : ''}</span>
                    )
                },
                options: platforms
            },
            { title: t('Username'), field: 'username' },
            { title: t('Password'), field: 'pass', type: 'password', render: rowData => {
                return ""
            } },
        ])
    }, [platforms])

    const loadData = async () => {
        const result = await Api.get(Api.getApiBaseUrl() + `auto-post-platform-configuration`)
        setData(result)
    }

    return (
        <React.Fragment>
            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={columns}
                data={data}
                title={t('Platform Configurations')}
                components={{
                    EditField: props => <MaterialTableCustomEditField {...props} />,
                }}
                actions={[
                    rowData => ({
                        icon: () => {
                            if (rowData.checked) {
                                return (<Done/>)
                            }

                            return (<AccountCircle/>)
                        },
                        onClick: async (event, rowData) => {
                            try {
                                // await Api.post(Api.getApiBaseUrl() + `auto-post/execute/${rowData.id}`, {
                                //     body: {}
                                // })

                                const result = await platformLoginFactory(rowData, setLoginComponent)

                                setLoginComponent('')

                                if (result) {
                                    loadData()
                                }
                                
                            }
                            catch (e) {
                                console.log('ERROR', e);
                            }
                        },
                        // disabled: rowData.session
                    })
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise(async (resolve, reject) => {
                            try {
                                newData = await Api.put(Api.getApiBaseUrl() + `auto-post-platform-configuration`, {
                                    body: newData,
                                })
                                await loadData()
                                resolve();
                            }
                            catch (e) {
                                console.log('ERROR', e);
                                reject(e)
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(async (resolve, reject) => {
                            try {
                                newData = await Api.put(Api.getApiBaseUrl() + `auto-post-platform-configuration`, {
                                    body: newData,
                                })
                                await loadData()
                                resolve();
                            }
                            catch (e) {
                                console.log('ERROR', e);
                                reject(e)
                            }
                        }),
                    onRowDelete: oldData =>
                        new Promise(async (resolve, reject) => {
                            try {
                                await Api.delete(Api.getApiBaseUrl() + `auto-post-platform-configuration/${oldData.id}`)
                                loadData()
                                resolve();
                            }
                            catch (e) {
                                console.log('ERROR', e);
                                reject(e)
                            }
                        }),
                }}
            />
            {loginComponent}
        </React.Fragment>
    )
}