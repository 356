/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MTableEditField } from 'material-table'

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: 500,
//         '& > * + *': {
//             marginTop: theme.spacing(3),
//         },
//     },
// }));


export default class MaterialTableCustomEditField extends MTableEditField {

    renderTextField() {
        let component = super.renderTextField()
        if (this.props.columnDef.type === 'password') {
            component = React.cloneElement(component, { ...component.props, type: 'password' })
        }

        return component
    }

    render() {
        let component = super.render()
        if (this.props.columnDef.options) {
            switch (this.props.columnDef.type) {
                case 'tags':
                    component = this.renderTagsField(this.props.columnDef.options)
                    break;
                case 'select':
                    component = this.renderSelectField(this.props.columnDef.options)
                    break;

                default:
                    break;
            }
        }


        return component
    }

    renderTagsField(options) {

        return (
            <Autocomplete

                {...this.getProps()}
                onChange={(event, value) => this.props.onChange(value)}
                multiple
                //   id="options-standard"
                options={options.map(x => x.id)}
                getOptionLabel={(option) => this.getOptionLabel(option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label=""
                        placeholder="Type"
                    />
                )}
            />
        )
    }

    renderSelectField(options) {

        return (
            <Autocomplete

                {...this.getProps()}
                onChange={(event, value) => this.props.onChange(value)}
                //   id="options-standard"
                options={options.map(x => x.id)}
                getOptionLabel={(option) => this.getOptionLabel(option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label=""
                        placeholder="Type"
                    />
                )}
            />
        )
    }

    getOptionLabel(option) {
        if (typeof option === 'object') {
            option = option.id
        }

        const t = this.props.columnDef.options.find(y => y.id === option)
        if (t)
            return t.name
        return ''
    }
}