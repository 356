
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import store from '../common/redux/store';
import { loginAction, updateAppData } from '../common/redux/actions';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginPage = (props) => {

    const [values, setValues] = useState({})
    const { t, i18n } = useTranslation();

    const handleSignIn = () => {
        store.dispatch(loginAction(values))
    }

    const handleChange = e => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            handleSignIn()
        }
    }

    if (props.showAuthenticationError) {
        setTimeout(() => {
            store.dispatch(updateAppData({
                showAuthenticationError: false
            }))
        }, 3000)
    }

    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('Login')}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        // id="email"
                        label="Email Address"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        // id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <Collapse in={props.showAuthenticationError}>
                        <Alert severity="error">{t('Authentication error')}</Alert>
                    </Collapse>
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        // type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSignIn}
                    >
                        {t('Login')}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            {/* <Link href="#" variant="body2">
                                Forgot password?
                            </Link> */}
                        </Grid>
                        <Grid item>
                            <Link href="/register" variant="body2">
                                {t('DontHaveAnAccountSignUp')}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        showAuthenticationError: state.app.showAuthenticationError
    }
}

export default connect(mapStateToProps)(LoginPage)
