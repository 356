import { makeStyles } from "@material-ui/core";
import MaterialTable from 'material-table';
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import Api from "../../common/api/api";
import MaterialTableCustomEditField from "../../common/utils/MaterialTableCustomEditField";
import tableIcons from "../../common/utils/MaterialTableIcons";
// import NewIndicationForm from './IndicationsForm'
import { useTranslation } from "react-i18next";
import PostFormDialog from "./PostFormDialog";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    fab: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
    },
}));

// const emptyPost = { platforms: [], images: [] }

export default function PlatformConfigurationIdTableComponent(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([])
    const tableRef = useRef(null);
    const [columns, setColumns] = useState([])
    const [platformConfigurations, setPlatformConfigurations] = useState([])

    useEffect(e => {
        if (tableRef.current) {
            //pasaba q cuando dabas adicionar una fila y cambiabas en el menu a otro listado, se mantenia abierto la edicion con datos del anterior
            tableRef.current.setState({
                ...tableRef.current.state,
                showAddRow: false
            })
        }

        // loadData();

        (async () => {
            const result = await Api.get(Api.getApiBaseUrl() + `auto-post-platform-configuration`)
            setPlatformConfigurations(result)
        })()
    }, [])

    useEffect(() => {
        setData(props.value)
    }, [props.value])

    useEffect(() => {
        setColumns([
            { title: t('URI'), field: 'uri' },
            {
                title: t('Platform Configuration'),
                field: 'platformConfiguration',
                type: 'select',
                render: (rowData) => {
                    if (!rowData['platformConfiguration']) {
                        return (
                            <span></span>
                        )
                    }

                    let to_show = ''

                    if (typeof rowData['platformConfiguration'] === "string") {
                        to_show = platformConfigurations.find((x) => x.id === rowData['platformConfiguration'])
                    } else {
                        to_show = platformConfigurations.find((x) => x.id === rowData['platformConfiguration'].id)
                    }
                    
                    return (
                        <span>{to_show ? to_show.name : ''}</span>
                    )
                },
                options: platformConfigurations
            },
        ])
    }, [platformConfigurations])

    // const loadData = async () => {
    //     const result = await Api.get(Api.getApiBaseUrl() + `auto-post-platform-configuration`)
    //     setData(result)
    // }

    return (
        <React.Fragment>
            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={columns}
                data={data}
                title={t('Platform Configurations')}
                components={{
                    EditField: props => <MaterialTableCustomEditField {...props} />,
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise(async (resolve, reject) => {
                            // try {
                            //     newData = await Api.put(Api.getApiBaseUrl() + `auto-post-platform-configuration`, {
                            //         body: newData,
                            //     })
                            //     // loadData()
                            //     resolve();
                            // }
                            // catch (e) {
                            //     console.log('ERROR', e);
                            //     reject(e)
                            // }
                            const v = props.value? props.value: []
                            props.onChange({
                                target: {
                                    name: 'platformConfigurationIds',
                                    value: [
                                        ...v,
                                        newData
                                    ]
                                }
                            })
                            resolve()
                        }),
                    // TODO: hacer el edit
                    // onRowUpdate: (newData, oldData) =>
                    //     new Promise(async (resolve, reject) => {
                    //         try {
                    //             newData = await Api.put(Api.getApiBaseUrl() + `auto-post-platform-configuration`, {
                    //                 body: newData,
                    //             })
                    //             // loadData()
                    //             resolve();
                    //         }
                    //         catch (e) {
                    //             console.log('ERROR', e);
                    //             reject(e)
                    //         }
                    //     }),
                    onRowDelete: oldData =>
                        new Promise(async (resolve, reject) => {
                            const newValue = props.value.slice(0, oldData.tableData.id).concat(props.value.slice(oldData.tableData.id+1))
                            props.onChange({
                                target: {
                                    name: 'platformConfigurationIds',
                                    value: newValue
                                }
                            })
                            resolve()
                        }),
                }}
            />
        </React.Fragment>
    )
}