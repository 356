import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const DEFAULT_LANG = 'es';

const saved_lang = localStorage.getItem('saved_lang') || DEFAULT_LANG;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: {
                    homeMenu: "Home",
                    loginMenu: "Login",
                    logoutMenu: "Logout",
                    test: 'Pricing',
                    //automaticCrud: 'Automatic CRUD',
                    //administration: 'Administration',
                    Admin: 'Administrator',
                    NewUser: 'Sign In',
                },
            },
            es: {
                translation: {
                    homeMenu: "Inicio",
                    loginMenu: "Entrar",
                    logoutMenu: "Salir",
                    test: 'Precio',
                    automaticCrud: 'Gestión de Nomencladores',
                    administration: 'Administración',
                    User: 'Usuario',
                    Profile: 'Perfil',
                    logout: 'Salir',
                    Password: 'Contraseña',
                    Save: 'Guardar',
                    Admin: 'Administrador',
                    Posts: 'Posts',
                    NewUser: 'Registrarse',
                    Name: 'Nombre',
                    'Next execution': 'Próxima ejecución',
                    Active: 'Activo',
                    Post: 'Publicación',
                    Message: 'Mensaje',
                    Hour: 'Hora',
                    Day: 'Dia',
                    Interval: 'Intervalo',
                    Platform: 'Plataforma',
                    Username: 'Usuario',
                    'Platform Configurations': 'Configuraciones de plataforma',
                    'Interval type': 'Tipo de intervalo',
                },
            },
        },
        lng: saved_lang,
        fallbackLng: DEFAULT_LANG,
        saveMissing: true,
        missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
            console.log('TRANSLATION MISSING: key', key);
        },

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n
