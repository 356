import { Grid, ImageList, ImageListItem, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Api from '../../common/api/api';
import { AddCircle, Close, Delete } from '@material-ui/icons';
import { Box, IconButton } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'space-around',
//         overflow: 'hidden',
//         backgroundColor: theme.palette.background.paper,
//     },
//     imageList: {
//         flexWrap: 'nowrap',
//         transform: 'translateZ(0)',
//     },
//     img: {
//         maxWidth: '100%',
//         maxHeight: '100%',
//         objectFit: 'cover',
//     },
// }));

// const images = [
//     {
//         img: 'https://example.com/image1.jpg',
//         title: 'Image 1',
//     },
//     {
//         img: 'https://example.com/image2.jpg',
//         title: 'Image 2',
//     },
//     {
//         img: 'https://example.com/image3.jpg',
//         title: 'Image 3',
//     },
//     // Add more images as needed
// ];

const useStyles = makeStyles((theme) => ({
    iconButton: {
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    },
  }));

export default function TwoColumnImageGallery(props) {
    const classes = useStyles();
    const [images, setImages] = useState([])

    useEffect(() => {
        setImages(props.images)
    }, [props.images])

    const handleDelete = (id) => {
        props.onDelete(id)
    }


    return (
        <Grid container spacing={2}>

            {
                images.map(image => {
                    return (
                        <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
                            <img src={Api.getApiBaseUrl() + 'file/get/' + image.id} alt={image.name} style={{ width: '100%' }} />
                            <div style={{ display: 'block', position: 'absolute', right: '10px', top: '10px' }}>
                                <Box boxShadow={3} borderRadius="50%" p={1}>
                                    <IconButton className={classes.iconButton} onClick={() => handleDelete(image.id)}>
                                        <Close />
                                    </IconButton>
                                </Box>
                            </div>
                        </Grid>
                    )
                })
            }

        </Grid>
    );
}