import store from '../redux/store'
import { updateAppData, logoutAction } from '../redux/actions'
import jwt_decode from 'jwt-decode'
import { push } from 'connected-react-router'

let is_refreshing = false
let pool = []

export default class Api {

    // static post(url, body, secured = true, contentType = 'application/json'){
    static post(url, customOptions = {}) {
        const defaults = {
            body: null,
            secured: true,
            sendAs: 'json'
        }
        const options = Object.assign({}, defaults, customOptions)
        const state = store.getState()
        const headers = {}

        if (options.secured) {
            const result = this.tokenCheckAndRefresh('post', url, options)
            if (result)
                return result
            headers['Authorization'] = 'Bearer ' + state.app.access_token
        }

        let newBody = options.body
        if (options.sendAs === 'json') {
            headers['Content-Type'] = 'application/json'
            newBody = JSON.stringify(options.body)
        }

        if (options.sendAs === 'multipart') {
            newBody = Api.convertBodyToFormData(options.body)
        }

        return new Promise((res, rej) => {

            fetch(url, {
                method: 'POST',
                headers,
                body: newBody
            })
                .then(response => {
                    if (!response.ok) {
                        console.log('ERROR', response);
                        rej(response)
                    }
                    return response.json()
                })
                .then(json => {
                    res(json)
                })
                .catch(e => {
                    rej(e)
                })
        })
    }

    // static put(url, body, secured = true, contentType = 'application/json') {
    static put(url, customOptions = {}) {
        const defaults = {
            body: null,
            secured: true,
            sendAs: 'json'
        }
        const options = Object.assign({}, defaults, customOptions)
        const state = store.getState()
        const headers = {}

        if (options.secured) {
            const result = this.tokenCheckAndRefresh('put', url, options)
            if (result)
                return result
            headers['Authorization'] = 'Bearer ' + state.app.access_token
        }

        let newBody = options.body
        if (options.sendAs === 'json') {
            headers['Content-Type'] = 'application/json'
            newBody = JSON.stringify(options.body)
        }

        if (options.sendAs === 'multipart') {
            newBody = Api.convertBodyToFormData(options.body)
        }

        return new Promise((res, rej) => {

            fetch(url, {
                method: 'PUT',
                headers,
                body: newBody
            })
                .then(response => {
                    if (!response.ok) {
                        console.log('ERROR', response);
                        rej(response)
                    }
                    return response.json()
                })
                .then(json => {
                    res(json)
                })
                .catch(e => {
                    rej(e)
                })
        })
    }

    // static patch(url, body, secured = true, contentType = 'application/json') {
    static patch(url, customOptions = {}) {
        const defaults = {
            body: null,
            secured: true,
            sendAs: 'json'
        }
        const options = Object.assign({}, defaults, customOptions)
        const state = store.getState()
        const headers = {}

        if (options.secured) {
            const result = this.tokenCheckAndRefresh('patch', url, options)
            if (result)
                return result
            headers['Authorization'] = 'Bearer ' + state.app.access_token
        }

        let newBody = options.body
        if (options.sendAs === 'json') {
            headers['Content-Type'] = 'application/json'
            newBody = JSON.stringify(options.body)
        }

        if (options.sendAs === 'multipart') {
            newBody = Api.convertBodyToFormData(options.body)
        }

        return new Promise((res, rej) => {

            fetch(url, {
                method: 'PATCH',
                headers,
                body: newBody
            })
                .then(response => {
                    if (!response.ok) {
                        console.log('ERROR', response);
                        rej(response)
                    }
                    return response.json()
                })
                .then(json => {
                    res(json)
                })
                .catch(e => {
                    rej(e)
                })
        })
    }

    // static get(url, body, secured = true, contentType = 'application/json') {
    static get(url, customOptions = {}) {
        const defaults = {
            body: null,
            secured: true,
            sendAs: 'json'
        }
        const options = Object.assign({}, defaults, customOptions)
        const state = store.getState()
        const headers = {}

        if (options.secured) {
            const result = this.tokenCheckAndRefresh('get', url, options)
            if (result)
                return result
            headers['Authorization'] = 'Bearer ' + state.app.access_token
        }

        return new Promise((res, rej) => {

            // let params = ""
            let params = "";
            if (options.body) {
                params = "?"
                for (var key in options.body) {
                    if (params !== "?") {
                        params += "&";
                    }
                    params += key + "=" + encodeURIComponent(options.body[key]);
                }
            }

            fetch(url + params, {
                method: 'GET',
                headers,
            })
                .then(response => {
                    if (!response.ok) {
                        console.log('ERROR', response);
                        rej(response)
                    }
                    return response.json()
                })
                .then(json => {
                    res(json)
                })
                .catch(e => {
                    rej(e)
                })
        })
    }

    // static delete(url, body, secured = true, contentType = 'application/json') {
    static delete(url, customOptions = {}) {
        const defaults = {
            body: null,
            secured: true,
            sendAs: 'json'
        }
        const options = Object.assign({}, defaults, customOptions)
        const state = store.getState()
        const headers = {}

        if (options.secured) {
            const result = this.tokenCheckAndRefresh('delete', url, options)
            if (result)
                return result
            headers['Authorization'] = 'Bearer ' + state.app.access_token
        }

        return new Promise((res, rej) => {

            fetch(url, {
                method: 'DELETE',
                headers,
            })
                .then(response => {
                    if (!response.ok) {
                        console.log('ERROR', response);
                        rej(response)
                    }
                    return response.json()
                })
                .then(json => {
                    res(json)
                })
                .catch(e => {
                    rej(e)
                })
        })
    }

    static tokenCheckAndRefresh(type, url, options) {
        const state = store.getState()

        if (!state.app.user && options.secured) {
            store.dispatch(push('/login'))
            return Promise.resolve()
        }

        // si el token de autorizacion expiro, lo refresqueteo
        if (state.app.user && this.is_auth_token_expired(state.app.user.exp) && !is_refreshing) {
            is_refreshing = true

            fetch(this.getApiBaseUrl() + 'auth/refreshToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refreshToken: state.app.user.refreshToken
                })
            })
                .then(response => {
                    return response.json()
                })
                .then(json => {
                    delete json.success
                    const user = jwt_decode(json.access_token)
                    user.refreshToken = json.refreshToken;
                    const payload = {
                        ...json,
                        user
                    }
                    sessionStorage.setItem('auth', JSON.stringify(payload))
                    store.dispatch(updateAppData(payload))
                })
                .catch(e => {
                    console.log('bateo refreshing token', e);
                    pool = []
                    store.dispatch(logoutAction())
                })
                .finally(() => {
                    is_refreshing = false
                    this.process_pool()
                })

        }

        // si se esta actualizando el token, pongo a las peticiones en cola
        if (is_refreshing) {
            return new Promise((res, rej) => {
                pool.push({
                    type,
                    url,
                    options,
                    res,
                    rej
                })
            })
        }

        return false
    }

    static is_auth_token_expired(exp_timestamp) {
        const exp_time = new Date(exp_timestamp * 1000)
        return new Date() > exp_time
    }

    static process_pool() {
        for (const e of pool) {
            switch (e.type) {
                case 'post':
                    this.post(e.url, e.options)
                        .then(result => {
                            e.res(result)
                        })
                        .catch(error => {
                            e.rej(error)
                        })
                    break;
                case 'get':
                    this.get(e.url, e.options)
                        .then(result => {
                            e.res(result)
                        })
                        .catch(error => {
                            e.rej(error)
                        })
                    break;
                case 'put':
                    this.put(e.url, e.options)
                        .then(result => {
                            e.res(result)
                        })
                        .catch(error => {
                            e.rej(error)
                        })
                    break;
                case 'patch':
                    this.patch(e.url, e.options)
                        .then(result => {
                            e.res(result)
                        })
                        .catch(error => {
                            e.rej(error)
                        })
                    break;
                case 'delete':
                    this.delete(e.url, e.options)
                        .then(result => {
                            e.res(result)
                        })
                        .catch(error => {
                            e.rej(error)
                        })
                    break;
                default:
            }
        }

        pool = []
    }

    static getApiBaseUrl() {
        // const r = RegExp('(.+)\\.[^.]+\\.[^.]+$', 'g').exec(window.location.hostname)
        // let url = process.env.REACT_APP_API_PROTOCOL + '://'
        // if(r && r.length === 2){
        //     return url + r[1] + '.' + process.env.REACT_APP_API_HOSTNAME + '/'
        // }
        // return url + process.env.REACT_APP_API_HOSTNAME + '/'
        return process.env.REACT_APP_API_URL
    }

    static convertBodyToFormData(body) {
        const newBody = new FormData();
        for (const field_name in body) {
            if (Object.hasOwnProperty.call(body, field_name)) {
                const field_value = body[field_name];
                if (Array.isArray(field_value) && field_value.length && field_value[0] instanceof File) {
                    for (const item of field_value) {
                        newBody.append(field_name, item)
                    }
                }
                else if(typeof field_value === 'object' && !(field_value instanceof File)){
                    newBody.append(
                        '#obj#-' + field_name,
                        // new Blob([JSON.stringify(field_value)], {type: "application/json"})
                        JSON.stringify(field_value)
                    )
                }
                else {
                    newBody.append(field_name, field_value)
                }
            }
        }

        return newBody;
    }
}