import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import Api from "../api/api";
import { useTranslation } from "react-i18next";

export default forwardRef((props, ref) => {

    const { t, i18n } = useTranslation();
    const [captcha, setCaptcha] = useState({})
    // const [value, setValue] = useState('')

    useEffect(() => {
        load()
    }, [])

    useImperativeHandle(ref, () => ({
        reload: load
      }));

    const load = async () => {
        const result = await Api.get(Api.getApiBaseUrl() + 'auth/captcha', {
            secured: false
        })
        setCaptcha(result)
        props.onChange({
            target: {
                name: 'captcha_id',
                value: result.id
            }
        })
    }

    // const handleChange = (e) => {
    //     console.log(e);
    //     setValue(e.target.value)
    //     props.onChange(e)
    // }

    return (
        <div>
            <img style={{width: '100%'}} src={captcha.image}/>
            <TextValidator
                margin="normal"
                required
                fullWidth
                label={t("Captcha")}
                name="captcha_value"
                value={props.captcha_value}
                onChange={props.onChange}
                validators={['required']}
                errorMessages={['this field is required']}
            />
        </div>
    )
})