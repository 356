import { CLEAR_AUTH } from './actions'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as permissions } from "react-redux-permissions"

const initialState = {
    access_token: '',
    refreshToken: '',
    user: null,
    showAuthenticationError: false
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_AUTH:
            return {
                ...state,
                access_token: '',
                refreshToken: '',
                user: null
            }
        default:
            return {
                ...state,
                ...action.payload,
            }
    }

    // return state
}

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    permissions,
    app: appReducer,
})

export default rootReducer