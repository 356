import { Divider, List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../common/api/api";

export default function AutomaticCRUDMenuListComponent(props) {
    const { t, i18n } = useTranslation();
    const [entities, setEntities] = useState({})

    useEffect(() => {
        Api.get(Api.getApiBaseUrl() + 'crud')
            .then(result => {
                setEntities(result);
            })
    }, [])

    // const items = 

    return (
        <List
            subheader={
                <ListSubheader>{t('automaticCrud')}</ListSubheader>
            }
        >
            {
                Object.keys(entities).map((key, index) => {
                    // entities[key];
                    return (
                        <ListItem
                            button
                            key={key}
                            onClick={() => props.changeView({name: 'automatic-crud', data: entities[key]})}
                        >
                            <ListItemText
                                primary={t(key)}
                            />
                        </ListItem>
                    )
                })
            }
        </List>
    );
}