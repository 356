
import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import Api from '../../common/api/api'
import { Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import store from '../../common/redux/store';
import { push } from 'connected-react-router';
import CaptchaField from '../../common/utils/CaptchaField';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: '#BD2E21',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUpPage() {
    const classes = useStyles();
    const [values, setValues] = useState({
        // firstName: 'New',
        // lastName: 'Client',
        // username: 'angelqba@gmail.com',
        // password: '123',
        username: ''
    });
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(true);
    const captchaRef = useRef();
    const formRef = useRef();
    const { t, i18n } = useTranslation();

    // const handleCaptchaChange = (value) => {
    //     setCaptchaValue(value)
    // }

    useEffect(() => {
        setValues({
            ...values,
            email: values.username
        })
    }, [values.username])

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (event) => {
        setIsSubmitButtonEnabled(false);
        try {
            const result = await Api.post(Api.getApiBaseUrl() + 'auth/register', {
                body: values,
                secured: false
            });

            if (!result.success) {
                setShowErrorMessage(true)
                setErrorMessage(t(result.msg))
                setTimeout(() => {
                    setShowErrorMessage(false)
                }, 5000)
                captchaRef.current.reload()
            }
            else {
                store.dispatch(push('/register/confirmation-email-sent'))
            }
        }
        catch (e) {
            setShowErrorMessage(true)
            setErrorMessage(t('Network Error'))
            setTimeout(() => {
                setShowErrorMessage(false)
            }, 5000)
            captchaRef.current.reload()
        }

        setIsSubmitButtonEnabled(true);
    }

    const handleSignUpClick = () => {
        formRef.current.submit()
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} color="primary">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('NewUser')}
                </Typography>
                <ValidatorForm className={classes.form} ref={formRef} onSubmit={handleSubmit}>
                    {/* <form className={classes.form} noValidate> */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextValidator
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                label={t('FirstName')}
                                autoFocus
                                type="text"
                                onChange={handleChange}
                                value={values.firstName}
                                InputLabelProps={{ shrink: true }}
                                validators={['required']}
                                errorMessages={[t('ThisFieldIsRequired')]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextValidator
                                autoComplete="lname"
                                required
                                name="lastName"
                                variant="outlined"
                                fullWidth
                                label={t('LastName')}
                                type="text"
                                onChange={handleChange}
                                value={values.lastName}
                                InputLabelProps={{ shrink: true }}
                                validators={['required']}
                                errorMessages={[t('ThisFieldIsRequired')]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                autoComplete="email"
                                required
                                name="username"
                                variant="outlined"
                                fullWidth
                                label={t('Email')}
                                type="text"
                                onChange={handleChange}
                                value={values.username}
                                InputLabelProps={{ shrink: true }}
                                validators={['required', 'isEmail']}
                                errorMessages={[t('ThisFieldIsRequired'), t('Invalid email')]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                autoComplete="password"
                                required
                                name="password"
                                variant="outlined"
                                // required
                                fullWidth
                                label={t('Password')}
                                type="password"
                                onChange={handleChange}
                                value={values.password}
                                InputLabelProps={{ shrink: true }}
                                validators={['required']}
                                errorMessages={[t('ThisFieldIsRequired')]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CaptchaField onChange={handleChange} captcha_value={values.captcha_value} captcha_id={values.captcha_id} ref={captchaRef}/>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_CLIENT_KEY}
                                onChange={(value) => handleChange({target: {name: 'captcha', value}})}
                                ref={captchaRef}
                            />
                        </Grid> */}
                    </Grid>
                    <Collapse in={showErrorMessage}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Collapse>
                    <Button
                        type="button"
                        fullWidth
                        size="large"
                        variant="contained"
                        disabled={!isSubmitButtonEnabled}
                        color="primary"
                        onClick={handleSignUpClick}
                        className={classes.submit}
                    >
                        {t('Register')}
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {t('AlreadyHaveAnAccountSignIn')}
                            </Link>
                        </Grid>
                    </Grid>
                </ValidatorForm>
                {/* </form> */}
            </div>
            {/* <Box mt={5}>
          <Copyright />
        </Box> */}
        </Container>
    );
}