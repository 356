import { Switch, Route, useRouteMatch } from "react-router-dom";
import HomePage from './HomePage'

import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import LoginPage from "./LoginPage";
import { useTranslation } from 'react-i18next';
import ProfilePage from "./ProfilePage";
import Permission from 'react-redux-permissions';
import store from "../common/redux/store";
import { logoutAction } from "../common/redux/actions";
import SignUpConfirmationEmailSentPage from "./signup/SignUpConfirmationEmailSentPage";
import SignUpPage from "./signup/SignUpPage";
import SignUpConfirmedPage from "./signup/SignUpConfirmedPage";
import SignUpInvalidLinkPage from "./signup/SignUpInvalidLinkPage";
import PostPage from "./PostPage";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

export default function Template() {

    let { path, url } = useRouteMatch();
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const handleTest = (lang) => {
        //TODO: No se si esto deberia guardarse con redux
        localStorage.setItem('saved_lang', lang)
        i18n.changeLanguage(lang)
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {/* Menu */}
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img src="/img/logo.png" style={{height: '50px'}} alt="logo"/>
                    &nbsp;
                    &nbsp;
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                        Auto Post
                    </Typography>
                    <nav>
                        <Link variant="button" color="textPrimary" href="/" className={classes.link}>
                            {t('homeMenu')}
                        </Link>
                        {/* <Permission allowed={["user"]}>
                            <Link variant="button" href="/profile" className={classes.link}>
                                {t('Profile')}
                            </Link>
                        </Permission> */}
                        <Permission allowed={["admin"]}>
                            <Link variant="button" href="/admin" className={classes.link}>
                                {t('Admin')}
                            </Link>
                        </Permission>
                        <Permission allowed={["access-mod-auto-post"]}>
                            <Link variant="button" href="/auto-post" className={classes.link}>
                                {t('Posts')}
                            </Link>
                        </Permission>
                        {/* <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                            Enterprise
                        </Link>
                        <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                            Support
                        </Link> */}
                    </nav>
                    <Permission except={["user"]}>
                        <Button href="/login" color="primary" variant="outlined" className={classes.link}>
                            {t('loginMenu')}
                        </Button>
                        <Button href="/register" color="primary" variant="outlined" className={classes.link}>
                            {t('NewUser')}
                        </Button>
                    </Permission>
                    <Permission allowed={["user"]}>
                        <Button color="primary" variant="outlined" className={classes.link} onClick={() => store.dispatch(logoutAction())}>
                            {t('logoutMenu')}
                        </Button>
                    </Permission>
                    <Link variant="button" color="textPrimary" href="#" className={classes.link} onClick={() => handleTest('es')}>
                        ES
                    </Link>
                    <Link variant="button" color="textPrimary" href="#" className={classes.link} onClick={() => handleTest('en')}>
                        EN
                    </Link>
                </Toolbar>
            </AppBar>
            {/* End Menu */}

            {/* Sections */}
            <Switch>
                <Route exact path={path}>
                    <HomePage />
                </Route>
                <Route path={`/login`}>
                    <LoginPage />
                </Route>
                <Route path={`/profile`}>
                    <ProfilePage />
                </Route>
                <Route path={`/register/confirmation-email-sent`}>
                    <SignUpConfirmationEmailSentPage />
                </Route>
                <Route path={`/register/confirmed`}>
                    <SignUpConfirmedPage />
                </Route>
                <Route path={`/register/invalid-link`}>
                    <SignUpInvalidLinkPage />
                </Route>
                <Route path={`/register`}>
                    <SignUpPage />
                </Route>
                <Route path={`/auto-post`}>
                    <PostPage />
                </Route>
            </Switch>

            {/* Footer */}
            {/* <Container maxWidth="md" component="footer" className={classes.footer}>
                <Grid container spacing={4} justify="space-evenly">
                    {footers.map((footer) => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            <Typography variant="h6" color="textPrimary" gutterBottom>
                                {footer.title}
                            </Typography>
                            <ul>
                                {footer.description.map((item) => (
                                    <li key={item}>
                                        <Link href="#" variant="subtitle1" color="textSecondary">
                                            {item}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container> */}
            {/* End footer */}
        </React.Fragment>
        // <div>
        //     <h1>Store</h1>
        //     <Switch>
        //         <Route exact path={path} component={HomePage} />
        //         <Route path={`/login`} >
        //             <h2>Login</h2>
        //         </Route>

        //     </Switch>
        // </div>
    )
}
