import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import { useTranslation } from 'react-i18next';
import { Button, Hidden, IconButton, ListSubheader } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AutomaticCRUDMenuListComponent from './AutomaticCRUD/AutomaticCRUDMenuListComponent';
import AutomaticCRUDListComponent from './AutomaticCRUD/AutomaticCRUDListComponent';
import Permission from 'react-redux-permissions';
import MenuIcon from '@material-ui/icons/Menu';
import store from '../common/redux/store';
import { logoutAction } from "../common/redux/actions";
import HomeComponent from './Home/HomeComponent'
import ProfileMenuListComponent from './Profile/ProfileMenuListComponent';
import ProfileForm from './Profile/ProfileForm';
import { push } from 'connected-react-router';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function AdminPage(props) {
    const classes = useStyles();
    const { window } = props;
    const { t, i18n } = useTranslation();
    const [currentView, setCurrentView] = useState({
        name: 'welcome',
        data: {}
    })
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let currentViewComponent = getCurrentViewComponent(currentView);

    const drawerContent = (
        <div>
            <div className={classes.drawerContainer}>
                <Divider />
                <ProfileMenuListComponent changeView={setCurrentView} />
                <Divider />
                {/* <Permission allowed={["monitor", "admin"]}>
                    <MediaMenuListComponent changeView={setCurrentView} />
                    <Divider />
                </Permission>
                <Permission allowed={["access-mod-raciality-graphs", "access-mod-dynamic-graphs"]}>
                    <ReportMenuListComponent changeView={setCurrentView} />
                    <Divider />
                </Permission>
                <Permission allowed={["access-mod-indications"]}>
                    <IndicationMenuListComponent changeView={setCurrentView} />
                    <Divider />
                </Permission>
                <Permission allowed={["access-mod-contracts", "access-mod-persons", "access-mod-daily-control"]}>
                    <HrMenuListComponent changeView={setCurrentView} />
                    <Divider />
                </Permission>
                <Permission allowed={["access-mod-docs"]}>
                    <DocumentMenuListComponent changeView={setCurrentView} />
                    <Divider />
                </Permission> */}
                <Permission allowed={["access-mod-crud"]}>
                    <AutomaticCRUDMenuListComponent changeView={setCurrentView} />
                </Permission>
            </div>
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        {t('administration')}
                    </Typography>
                    {/* <Link color="inherit" href='/'> {t('Home').toUpperCase()}</Link> */}
                    <Button color="inherit" onClick={() => store.dispatch(push('/'))}> {t('Home')}</Button>
                    <Button color="inherit" onClick={() => alert('De momento ve a la opcion de perfil en el panel izquierdo')}>{t('Profile')}</Button>
                    <Button color="inherit" onClick={() => store.dispatch(logoutAction())}>{t('logout')}</Button>
                </Toolbar>
            </AppBar>
            {/* <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {drawerContent}
            </Drawer> */}
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawerContent}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawerContent}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {currentViewComponent}
            </main>
        </div>
    );
}

function getCurrentViewComponent(currentView) {
    switch (currentView.name) {
        case 'automatic-crud':
            return <AutomaticCRUDListComponent entity={currentView.data} />
        // case 'media':
        //     return <MediaListComponent media={currentView.data} />
        // case 'media-all':
        //     return <MediaListComponentWithFilters />
        // case 'contracts':
        //     return <ContractComponent data={currentView.data} />
        // case 'daily-work':
        //     //return <HrListDailyWorkComponent data={currentView.data} />
        //     return <DailyWorkComponent data={currentView.data} />
        // case 'indications':
        //     return <IndicationsComponent data={currentView.data} />
        // case 'documents':
        //     return <DocumentsComponent data={currentView.data} />
        // case 'reports':
        //     return <ReportComponent data={currentView.data} />
        // case 'raciality-reports':
        //     return <RacialityReportComponent data={currentView.data} />
        // case 'tags-reports':
        //     return <TagsReportComponent data={currentView.data} />
        // case 'people':
        //     return <PeopleComponent data={currentView.data} />
        // case 'users':
        //     return <UserComponent data={currentView.data} />
        case 'welcome':
            return <HomeComponent data={currentView.data} />
        case 'profile':
            return <ProfileForm data={currentView.data} />
        default:
            return ''
    }
}
