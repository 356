import './App.css';
import { Provider } from 'react-redux'
import store, { history } from './common/redux/store'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import {HOC} from "react-redux-permissions";
import Template from './front/Template'
import AdminPage from './admin/AdminPage'
import SyncQueue from './common/utils/SyncQueue';
import './common/i18n/i18n'

const baseTheme = createTheme();

function App() {
    const AdminPageComponent = HOC(
        ["admin", "monitor", "user"],  // Allowed
        [],  // disallowed
      )(AdminPage, <Redirect to='/login' />)

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={baseTheme}>
                    <SyncQueue/>
                    <Router history={history}>
                        <Switch>
                            <Route path="/admin" exact component={AdminPageComponent} />
                            <Route path="/" component={Template} />
                            {/* <Route path="/register" component={SignUpPage} /> */}
                            {/* <Route path="/login" component={SignInPage} /> */}
                            {/* <Route path="/dashboard" component={HOC(["dashboard"])(DashboardPage, <Redirect to='/login' />)} /> */}
                            {/* <Route path="/:id" exact component={ProspectPage} /> */}


                            {/* <Route component={NotFound} /> */}
                        </Switch>
                    </Router>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    );
}

export default App;
