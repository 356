import {createStore, applyMiddleware, compose} from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import {loadAuthFromStore} from './actions'

export const history = createBrowserHistory()

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    rootReducer(history), 
    {},
    composeEnhancer(
        applyMiddleware(
          routerMiddleware(history),
          thunk
        ),
      ),
    )

window.store = store

store.dispatch(loadAuthFromStore())

export default store