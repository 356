import { Container, makeStyles, Paper } from "@material-ui/core";
import MaterialTable from 'material-table';
import { useRef } from "react";
import { useEffect, useState } from "react";
import Api from "../../common/api/api";
import MaterialTableCustomEditField from "../../common/utils/MaterialTableCustomEditField";
import tableIcons from "../../common/utils/MaterialTableIcons";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
}));

export default function AutomaticCRUDListComponent(props) {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [columnsExtraInfo, setColumnsExtraInfo] = useState({})
    const tableRef = useRef(null);

    useEffect(e => {

        if (tableRef.current) {
            //pasaba q cuando dabas adicionar una fila y cambiabas en el menu a otro listado, se mantenia abierto la edicion con datos del anterior
            tableRef.current.setState({
                ...tableRef.current.state,
                showAddRow: false
            })
        }

        Api.get(Api.getApiBaseUrl() + `crud/${props.entity.name}`)
            .then(result => {
                const converted_result = result.map(row => {
                    const new_row = { ...row }
                    for (const key in row) {
                        if (Object.hasOwnProperty.call(row, key)) {
                            const column_value = row[key];
                            const newLocal = column_value !== null && typeof column_value === 'object' && !(column_value instanceof Array);
                            if (newLocal) {
                                new_row[key] = column_value.id
                            }
                        }
                    }

                    return new_row;
                })
                setData(converted_result);

                // carga lista de valores para selects en la tabla
                (async () => {

                    const new_columns = []
                    for (const column of props.entity.columns) {
                        let column_definition = { title: format_column_name(column.name), field: column.name }
                        if (column.relation_info) {
                            const options = await Api.get(Api.getApiBaseUrl() + `crud/${column.relation_info.related_entity}/names`);
                            let type;
                            let render;
                            if (column.relation_info.relation_type === 'many-to-one' || column.relation_info.relation_type === 'one-to-one') {
                                type = 'select'
                                render = (rowData) => {
                                    const to_show = options.find((x) => x.id === rowData[column.name])
                                    return (
                                        <span>{to_show ? to_show.name : ''}</span>
                                    )
                                }
                            }
                            else {
                                type = 'tags';
                                render = (rowData) => {
                                    const ids = rowData[column.name] || [];
                                    const to_show = ids.map(y => {
                                        if(!y){
                                            return ''
                                        }
                                        
                                        return options.find((x) => x.id === y).name
                                    }).join(', ')
                                    // const to_show = options.find((x) => x.id === rowData[column.name])
                                    return (
                                        <span>{to_show ? to_show : ''}</span>
                                    )
                                }
                            }
                            column_definition = {
                                ...column_definition,
                                type: type,
                                options: options,
                                render: render,
                            }
                        }
                        else if (column.type === 'Boolean') {
                            column_definition.type = 'boolean'
                        }
                        else if (column.type === 'Date') {
                            column_definition.type = 'date'
                        }
                        else if (column.name === 'id') {
                            column_definition.editable = 'never';
                        }

                        new_columns.push(column_definition)
                    }

                    setColumns(new_columns)
                })()

            })


    }, [props.entity])

    return (
        <Container >
            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={columns}
                data={data}
                title={props.entity.name}
                components={{
                    EditField: props => <MaterialTableCustomEditField {...props} />,
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise(async (resolve, reject) => {
                            try {
                                newData = await Api.post(Api.getApiBaseUrl() + `crud/${props.entity.name}`, {
                                    body: newData,
                                })
                                setData([...data, newData]);
                                resolve();
                            }
                            catch (e) {
                                console.log('ERROR', e);
                                reject(e)
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(async (resolve, reject) => {
                            try {
                                newData = await Api.patch(Api.getApiBaseUrl() + `crud/${props.entity.name}/${oldData.id}`, {
                                    body: newData,
                                })
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                resolve();
                            }
                            catch (e) {
                                console.log('ERROR', e);
                                reject(e)
                            }
                        }),
                    onRowDelete: oldData =>
                        new Promise(async (resolve, reject) => {
                            try {
                                await Api.delete(Api.getApiBaseUrl() + `crud/${props.entity.name}/${oldData.id}`)
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                                resolve();
                            }
                            catch (e) {
                                console.log('ERROR', e);
                                reject(e)
                            }
                        }),
                }}
            // options={{
            //     pageSize: 1,
            //     pageSizeOptions: [1, 5, 10, 20]
            // }}
            />
        </Container>
    )
}

function format_column_name(string) {
    return (string.charAt(0).toUpperCase() + string.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2');
}