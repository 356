import React from 'react';
import Carousel from 'nuka-carousel';
import "./slider.css";

export default class extends React.Component {
  render() {
    return (
      <Carousel>
        <div style={{height: "401px", backgroundImage:"url(/img/Carousel01.png)"}} class="diapo"><div style={{color: "white", paddingTop:"160px", marginLeft:"60px"}}><h3>Titulo</h3><p>lalal lalal lalal lalal lalal lalal</p></div></div>
        <div style={{height: "100px"}}><p>lalal lalal lalal lalal lalal lalal</p><p>lalal lalal lalal lalal lalal lalal</p></div>
        <div style={{height: "100px"}}><p>lalal lalal lalal lalal lalal lalal</p></div>
      </Carousel>
    );
  }
}