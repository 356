import { Container } from "@material-ui/core";
import React from "react";
import PostTableComponent from "./post/PostTableComponent";
import PlatformConfigurationTableComponent from "./post/PlatformConfigurationTableComponent";

export default function PostPage(props) {

    return (
        <React.Fragment>
            <Container >
                <PostTableComponent/>
                <PlatformConfigurationTableComponent/>
            </Container>
        </React.Fragment>
    )
}