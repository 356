import { Component, useEffect, useRef, useState } from 'react'
import { connect } from "react-redux"
// import { updateData, loadData } from '../redux/actions';
import store from '../redux/store';
import uuid from 'react-uuid'
import Api from '../api/api'
// import { updateGlobalState } from '../redux/actions'

export default function SyncQueue(props) {

    const intervalHandler = useRef(null)

    useEffect(() => {
        doSync()
        intervalHandler.current = setInterval(doSync, process.env.REACT_APP_SYNC_TIME * 1000)

        return () => {
            clearInterval(intervalHandler.current)
        }
    }, [])

    return null;
}

const doSync = async () => {
    const sync_info = getSyncInfo()

    const new_tasks = [];
    console.log('sync');
    if (sync_info.queue) {
        let force_exit = false
        while (sync_info.queue.length && !force_exit) {
            const task = sync_info.queue.shift()
            switch (task.type) {
                case 'cache-first-get':
                    try {
                        console.log('cache-first-get');
                        // primero buscar si la info esta en la cache
                        const in_cache = localStorage.getItem(task.url)
                        if (in_cache) {
                            console.log('esta en la cache');
                            // si esta, ejecuta evento con el resutado y trata de actualizar de la red
                            const parsed = JSON.parse(in_cache)
                            document.dispatchEvent(new CustomEvent(task.event, { detail: parsed }))
                            try {
                                const result = await Api.get(task.url)
                                console.log('actualizo desde la red');
                                // si le responde, actualiza lo guardado y evento con el resutado
                                const in_text = JSON.stringify(result)
                                if (in_cache !== in_text) {
                                    //solo actualiza si lo guardado en la cache es diferente a lo recibido
                                    localStorage.setItem(task.url, in_text)
                                    document.dispatchEvent(new CustomEvent(task.event, { detail: result }))
                                }
                            }
                            catch (e) {
                                // si no, no hace nada
                                console.log('resultado fula de la red. no hago na');
                            }
                        }
                        else {
                            //si no, intenta actualizar de la red
                            console.log('no esta en la cache');
                            try {
                                const result = await Api.get(task.url)
                                // si le responde, actualiza lo guardado y evento con el resutado
                                console.log('actualizo la cache');
                                localStorage.setItem(task.url, JSON.stringify(result))
                                document.dispatchEvent(new CustomEvent(task.event, { detail: result }))
                            }
                            catch (e) {
                                // si no, evento con valor vacio
                                console.log('devuelvo vacio');
                                localStorage.setItem(task.url, JSON.stringify([]))
                                document.dispatchEvent(new CustomEvent(task.event, { detail: [] }))
                            }
                        }

                        const result = await Api.get(task.url)
                        document.dispatchEvent(
                            new CustomEvent(
                                task.event,
                                {
                                    detail: result
                                }
                            )
                        )
                    }
                    catch (e) {
                        // reinserta tarea fallida y para el procesamiento de la cola
                        sync_info.queue.unshift(task)
                        force_exit = true
                    }
                    break;

                case 'persistent-post':
                    //trata de enviar la info por la red
                    try {
                        await Api.post(task.url, {
                            body: task.data
                        })
                    }
                    catch (e) {
                        //si no pudo, pone la tarea pal final de la cola
                        new_tasks.push(task)
                    }
                    break;

                case 'persistent-patch':
                    //trata de enviar la info por la red
                    try {
                        await Api.patch(task.url, {
                            body: task.data
                        })
                    }
                    catch (e) {
                        //si no pudo, pone la tarea pal final de la cola
                        new_tasks.push(task)
                    }
                    break;
                case 'offline-add':
                    if (Array.isArray(task.offline_storage)) {
                        //en offline_storage se espera un array de la siguiente forma
                        // [
                        //     {
                        //         storage: 'storage 1',
                        //         values: [
                        //             //value 1,
                        //             //value 2,
                        //             //....
                        //         ]
                        //     },
                        //     {
                        //         storage: 'storage 2',
                        //         values: [
                        //             //value 1,
                        //             //value 2,
                        //             //....
                        //         ]
                        //     },
                        // ]

                        for (const storage of task.offline_storage) {
                            //obtengo lo q esta guardado
                            let in_cache = localStorage.getItem(storage.storage)
                            //inicializo si no esta en cache
                            if (!in_cache) {
                                in_cache = '[]'
                                localStorage.setItem(storage.storage, in_cache)
                            }

                            const parsed = JSON.parse(in_cache)
                            parsed.push(...storage.values)
                            localStorage.setItem(storage.storage, JSON.stringify(parsed))
                        }
                    }
                    else { //simple cache update. se espera q en offline_storage venga la cache a actualizar
                        //obtengo lo q esta guardado
                        let in_cache = localStorage.getItem(task.offline_storage)
                        //inicializo si no esta en cache
                        if (!in_cache) {
                            in_cache = '[]'
                            localStorage.setItem(task.offline_storage, in_cache)
                        }

                        //actualizo la cache
                        const parsed = JSON.parse(in_cache)
                        parsed.push(task.data)
                        localStorage.setItem(task.offline_storage, JSON.stringify(parsed))
                    }





                    //adiciono tarea para q envie la info al server
                    new_tasks.push({
                        type: 'persistent-post',
                        url: task.url,
                        data: task.data,
                    })
                    // addToQueue({
                    //     type: 'persistent-post',
                    //     url: task.url,
                    //     data: task.data,
                    // })   
                    break;
                case 'offline-update':
                    //obtengo lo q esta guardado
                    let in_cache = localStorage.getItem(task.offline_storage)
                    //inicializo si no esta en cache
                    if (!in_cache) {
                        in_cache = '[]'
                        localStorage.setItem(task.offline_storage, in_cache)
                    }

                    //actualizo la cache
                    const parsed = JSON.parse(in_cache)
                    // parsed.push(task.data)
                    const index = parsed.findIndex(x => x.id === task.data.id)
                    parsed[index] = task.data
                    localStorage.setItem(task.offline_storage, JSON.stringify(parsed))





                    //adiciono tarea para q envie la info al server
                    new_tasks.push({
                        type: 'persistent-patch',
                        url: task.url,
                        data: task.data,
                    })
                    // addToQueue({
                    //     type: 'persistent-post',
                    //     url: task.url,
                    //     data: task.data,
                    // })   
                    break;
                default:
                    break;
            }
        }

        sync_info.queue.push(...new_tasks)
        // guarda la cola actualizada
        setSyncInfo(sync_info)
    }
}

const getSyncInfo = () => {
    const temp = localStorage.getItem('sync_queue')
    if (!temp) {
        const new_sync_info = {
            lastUpdate: Date.now(),
            queue: []
        }
        localStorage.setItem('sync_queue', JSON.stringify(new_sync_info))
        return new_sync_info
    }

    return JSON.parse(temp);
}

const getQueue = () => {
    return getSyncInfo().queue
}

const setSyncInfo = new_sync_ifo => {
    new_sync_ifo.lastUpdate = new Date();
    localStorage.setItem('sync_queue', JSON.stringify(new_sync_ifo))
}

const addToQueue = (task, process_now) => {
    const sync_info = getSyncInfo();
    sync_info.queue.push(task)
    // sync_info.lastUpdate = new Date();
    setSyncInfo(sync_info)
    if (process_now) {
        doSync()
    }
}

SyncQueue.testy = () => { console.log('testy3'); }
SyncQueue.getSyncInfo = getSyncInfo;
SyncQueue.getQueue = getQueue;
SyncQueue.addToQueue = addToQueue;
SyncQueue.doSync = doSync;

// class SyncQueue extends Component {

//     doSync = async () => {

//         // TODO: Rehacer este metodo completo

//         const sync_queue = JSON.parse(localStorage.getItem('sync_queue'))

//         if (!this.lastCheck || this.lastCheck <= sync_queue.lastUpdate) {
//             if (sync_queue.queue) {

//                 const sync_status_element_id = uuid()
//                 let reload = false
//                 let error = null


//                 // TODO: Hacer
//                 // store.dispatch(updateGlobalState({
//                 //     status_list: store.getState().app.global.status_list.concat([{
//                 //         id: sync_status_element_id,
//                 //         type: 'indefinite',
//                 //         text: 'Sync'
//                 //     },
//                 //     ])
//                 // }))

//                 for(const task of sync_queue.queue){
//                     let result = null

//                     try{
//                         result = await fetch('/api_evv/process_queue_item', {
//                             method: 'POST',
//                             body: JSON.stringify(task),
//                             headers: {
//                                 'Content-Type': 'application/json',
//                                 'Accept': 'application/json',
//                             }
//                         })
//                         result = await result.json()
//                         reload = true
//                     }
//                     catch(e){
//                         result = null
//                         error = e
//                     }

//                     if (result) {
//                         if(result.error){
//                             error = result.error
//                         }
//                         else if(result.result.success){
//                             this.lastCheck = Date.now()
//                             // tengo que obtener la cola de nuevo, porque pudo haberse actualizado
//                             const current_sync_queue = JSON.parse(localStorage.getItem('sync_queue'))
//                             current_sync_queue['queue'].shift()
//                             localStorage.setItem('sync_queue', JSON.stringify(current_sync_queue))
//                         }
//                     }

//                     if(error){
//                         // tengo que obtener la cola de nuevo, porque pudo haberse actualizado
//                         const current_sync_queue = JSON.parse(localStorage.getItem('sync_queue'))
//                         current_sync_queue.queue[0]['last_error'] = error.message
//                         if(error.data){
//                             current_sync_queue.queue[0]['last_error'] += ': ' + error.data.arguments[0]
//                         }
//                         localStorage.setItem('sync_queue', JSON.stringify(current_sync_queue))
//                     }

//                 }

//                 if(reload){
//                     // TODO: Hacer
//                     // store.dispatch(loadData())
//                 }

//                 const new_status_list = [].concat(store.getState().app.global.status_list).filter(x => x.id !== sync_status_element_id)

//                 // TODO: Hacer
//                 // store.dispatch(updateData({
//                 //     status_list: new_status_list
//                 // }))
//             }

//         }

//         if (this.continueChecking) {

//             setTimeout(this.doSync, process.env.REACT_APP_SYNC_TIME * 1000)
//         }
//     }

//     componentDidMount() {
//         this.continueChecking = true
//         const temp = localStorage.getItem('sync_queue')

//         if (!temp) {
//             localStorage.setItem('sync_queue', JSON.stringify({
//                 lastUpdate: Date.now(),
//                 queue: []
//             }))
//         }

//         this.doSync()
//         // this.setIntervalHandler = setInterval(() => {



//         // }, 10000)
//     }

//     componentWillUnmount() {
//         // clearInterval(this.setIntervalHandler)
//         this.continueChecking = false
//     }

//     render() {
//         return null
//     }

//     static getQueue(){
//         const queue = localStorage.getItem('sync_queue')
//         if (queue) {
//             return JSON.parse(queue)['queue']
//         }

//         return null
//     }

//     static setQueue(new_queue){
//         const queue = localStorage.getItem('sync_queue')
//         if (queue) {
//             const parsed_queue = JSON.parse(queue)
//             parsed_queue['lastUpdate'] = new Date();
//             parsed_queue['queue'] = [...new_queue];

//             localStorage.setItem('sync_queue', JSON.stringify(new_queue))
//         }
//     }
// }

// const mapStateToProps = (state) => {
//     return {
//         sync_queue: state.app.sync_queue,
//     }
// };

// export default connect(mapStateToProps, null)(SyncQueue);