import { Container, Fab, makeStyles, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import React from "react";
import { useEffect, useState } from "react";
import Api from "../../common/api/api";
import MaterialTableCustomEditField from "../../common/utils/MaterialTableCustomEditField";
import tableIcons from "../../common/utils/MaterialTableIcons";
import { useTranslation } from "react-i18next";
import SyncQueue from "../../common/utils/SyncQueue";

export default function HomeComponent(props) {

    return (
        <div></div>
    )
}