import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useEffect, useState } from "react";
import Api from "../../../common/api/api";

export default function FacebookLoginWizard(props) {

    const [text, setText] = useState("Working...")

    useEffect(() => {
        login()
    }, [])

    const login = async () => {
        const result = await Api.post(Api.getApiBaseUrl() + `platform-facebook/login/${props.rowData.id}`, {
            body: {}
        })

        if (result.success) {
            props.handleClose(true)
        }
        else {
            setText('Error')
        }
    }

    return (
        <Dialog open={true} onClose={() => props.handleClose(false)}>
            <DialogTitle>Facebook Login</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
            </DialogActions> */}
        </Dialog>
    )
}