import FacebookLoginWizard from "./facebookLoginWizard";
import WhatsAppLoginWizard from "./whatsappLoginWizard";

export default function platformLoginFactory(rowData, setComponent) {
    return new Promise((resolve, reject) => {
        console.log('rowData', rowData);
        if (rowData.__platform__.id === '6c877049-9328-4d7c-87b0-fabc4107ae55') {
            setComponent(<FacebookLoginWizard handleClose={(result) => {
                resolve(result)
            }} rowData={rowData} />)
        }

        if (rowData.__platform__.id === '02c92ded-355d-446c-ab5b-d5e3aa2ab24d') {
            setComponent(<WhatsAppLoginWizard handleClose={(result) => {
                resolve(result)
            }} rowData={rowData} />)
        }
    })
}