import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
// import CloseIcon from '@mui/icons-material/Close';
import { Close } from "@material-ui/icons";
import { useEffect, useRef, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete, MenuItem } from '@mui/material';
import Api from '../../common/api/api';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { toISOLocal } from '../../common/utils/utils';
import TwoColumnImageGallery from './TwoColumnImageGallery';
import PlatformConfigurationIdTableComponent from './PlatformConfigurationIdTableComponent';
import moment from 'moment';
// import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function PostFormDialog(props) {
    const { t, i18n } = useTranslation();
    const formRef = useRef(null)
    const [data, setData] = useState({ platformConfigurations: [], images: [] })
    const [platformConfigurations, setPlatformConfigurations] = useState([])

    useEffect(() => {
        (async () => {
            const result = await Api.get(Api.getApiBaseUrl() + `auto-post-platform-configuration`)
            setPlatformConfigurations(result)
        })()
    }, [])

    useEffect(() => {
        const newData = {
            ...props.data
        }

        if (newData && newData.nextExecution) {
            // const newdt = toISOLocal(new Date(newData.nextExecution))
            const newdt = moment(newData.nextExecution).local().format('YYYY-MM-DD HH:mm:ss Z')
            newData.nextExecution = newdt
        }

        setData(newData)
    }, [props.data])

    const handleSave = (event) => {
        formRef.current.submit()
    }

    const handleSubmit = async () => {
        const result = await Api.put(Api.getApiBaseUrl() + 'auto-post', {
            body: data,
            sendAs: 'multipart'
        })

        if (result.success) {
            props.handleSave()
        }
        else {
            console.error('algo salio mal', result);
        }
    }

    const handleChange = (e) => {
        const values = {
            ...data,
        }
        values[e.target.name] = e.target.value

        setData(values)
    }

    const handleImageDelete = id => {
        handleChange({
            target: {
                name: 'images',
                value: data.images.filter(x => x.id !== id)
            }
        })
    }

    return (
        <BootstrapDialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            fullScreen
            open={props.open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                {t('Post')}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <ValidatorForm noValidate onSubmit={handleSubmit} sx={{ mt: 1 }} ref={formRef}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextValidator
                                margin="normal"
                                required
                                fullWidth
                                label={t("Name")}
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={data.name}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />

                            {/* <TextValidator
                                margin="normal"
                                required
                                fullWidth
                                label={t("URI")}
                                name="uri"
                                autoComplete="uri"
                                value={data.uri}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            /> */}

                            {/* <TextValidator // TODO: cambiar esto por un textarea o similar
                                margin="normal"
                                required
                                minRows={10}
                                multiLine
                                fullWidth
                                label={t('Message')}
                                name="message"
                                autoComplete="message"
                                value={data.message}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            /> */}
                            <TextField
                                margin="normal"
                                name="message"
                                required
                                fullWidth
                                label={t('Message')}
                                autoComplete="message"
                                value={data.message}
                                onChange={handleChange}
                                multiline
                                minRows={10}
                                // InputProps={{ style: { width: "300px" } }}
                                />
                                
                            <SelectValidator
                                name="interevalType"
                                margin="normal"
                                required
                                fullWidth
                                label={t('Interval type')}
                                value={!data.interevalType ? '' : data.interevalType}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            >
                                <MenuItem value={"hour"}>{t("Hour")}</MenuItem>
                                <MenuItem value={"day"}>{t("Day")}</MenuItem>
                            </SelectValidator>

                            <TextValidator
                                margin="normal"
                                required
                                fullWidth
                                type='number'
                                label={t("Interval")}
                                name="interval"
                                autoComplete="interval"
                                value={data.interval}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />

                            <TextValidator
                                margin="normal"
                                required
                                fullWidth
                                type='datetime-local'
                                label={t("Next execution")}
                                name="nextExecution"
                                autoComplete="nextExecution"
                                value={data.nextExecution? moment(data.nextExecution).format('YYYY-MM-DD HH:mm:ss'): ''}
                                // onChange={handleChange}
                                onChange={(e) => {
                                    handleChange({
                                        target: {
                                            name: 'nextExecution',
                                            value: moment(e.target.value).local().format('YYYY-MM-DD HH:mm:ss Z')
                                        }
                                    })
                                }}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />

                            {/* <Autocomplete
                                margin="normal"
                                required
                                fullWidth
                                multiple
                                label={t('Platform Configurations')}
                                value={data.platformConfigurations}
                                onChange={(e, v) => handleChange({ target: { name: 'platformConfigurations', value: v } })}
                                getOptionLabel={x => x ? x.name : ''}
                                options={platformConfigurations}
                                // sx={{ width: 300 }}
                                renderInput={(params) => <TextValidator required {...params} label={t('Platform Configurations')} />}
                            /> */}

                            <PlatformConfigurationIdTableComponent value={data.platformConfigurationIds} onChange={handleChange}/>

                            <FormGroup>
                                <FormControlLabel control={<Checkbox active />} label={t('Active')} checked={data.active} onChange={(e) => handleChange({
                                    target: {
                                        name: 'active',
                                        value: e.target.checked
                                    }
                                })} />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TwoColumnImageGallery images={data.images} onDelete={handleImageDelete} />
                            <DropzoneArea
                                filesLimit={50}
                                onChange={file => handleChange({
                                    target: {
                                        name: 'files',
                                        value: file
                                    }
                                })}
                            />
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' onClick={handleSave}>
                    Save
                </Button>
                <Button color='secondary' variant='outlined' autoFocus onClick={props.handleClose}>
                    Close
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}