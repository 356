
import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import store from '../../common/redux/store';
import { loginAction } from '../../common/redux/actions';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import Api from '../../common/api/api'

const useStyles = makeStyles((theme) => ({
    
}));

export default function SignUpConfirmedPage() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <div style={{textAlign: 'center', marginTop: '25px'}}>
                <CheckCircleOutlineIcon style={{fontSize: '50px'}}/>
            </div>
            
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              {t('Account Created')}
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              {t('Now you can login with your credentials')}
            </Typography>
        </Container>
    );
}