import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import BlockIcon from '@material-ui/icons/Block';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    
}));

export default function SignUpInvalidLinkPage() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <div style={{textAlign: 'center', marginTop: '25px'}}>
                <BlockIcon style={{fontSize: '50px'}}/>
            </div>
            
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              {t('Invalid Link')}
            </Typography>
        </Container>
    );
}