import { Divider, List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function ProfileMenuListComponent(props) {
    const { t, i18n } = useTranslation();

    // const items = 

    return (
        <List
            subheader={
                <ListSubheader>{t('Profile')}</ListSubheader>
            }
        >
            <ListItem
                button

                onClick={() => props.changeView({ name: 'profile', })}
            >
                <ListItemText
                    primary={t('Profile')}
                />
            </ListItem>
        </List>
    );
}