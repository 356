import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useEffect, useState } from "react";
import Api from "../../../common/api/api";

export default function WhatsAppLoginWizard(props) {

    // const [text, setText] = useState("Working...")
    const [qr, setQR] = useState('')

    useEffect(() => {
        login()
    }, [])

    const login = async () => {
        const result = await Api.post(Api.getApiBaseUrl() + `platform-whatsapp/login/${props.rowData.id}`, {
            body: {}
        })

        if (result.qr) {
            // props.handleClose(true)
            setQR(result.qr)
        }
        else {
            // setQR('')
            alert('error')
        }
    }

    let content = "Working..."

    if (qr) {
        content = (
            <img src={qr} alt="QR" />
        )
    }

    return (
        <Dialog open={true} onClose={() => props.handleClose(false)}>
            <DialogTitle>WhatsApp Login</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}